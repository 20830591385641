<template>
  <section id="rooms" aria-label="Rooms" class="Rooms__container">
    <div class="Rooms__content-wrapper">
      <div class="Rooms__wrapper">
        <h2 class="Rooms__heading">
          Audio & video meetings available in one tool
        </h2>
        <ul class="Rooms__list">
          <li class="Rooms__list-element">
            <div class="Rooms__title-wrapper">
              <DashboardIconVideo :expired="false" />
              <h3 class="Rooms__title">Video Rooms</h3>
            </div>
            <p class="Rooms__paragraph">
              Discover fresh & unique video calls designed to help remote teams
              create, collaborate and celebrate together.
            </p>
          </li>
          <li class="Rooms__list-element">
            <div class="Rooms__title-wrapper">
              <DashboardIconAudio :expired="false" />
              <h3 class="Rooms__title">Audio Rooms</h3>
            </div>
            <p class="Rooms__paragraph">
              Enjoy all perks of video meeting without the pressure or
              expectation to have your camera on. Perfect solution for data
              consumption sensitive users.
            </p>
          </li>
          <li class="Rooms__list-element">
            <div class="Rooms__title-wrapper">
              <DashboardIconStreamed :expired="false" />
              <h3 class="Rooms__title">Streamed&nbsp;Rooms</h3>
              <LandingIconComingSoon class="Rooms__coming-soon" />
            </div>
            <p class="Rooms__paragraph">
              Dive into captivating streams prepared by hosts, featuring
              immersive viewing, interactive chat, and emoji reactions.
            </p>
          </li>
        </ul>
        <div class="Rooms__videos-wrapper">
          <div class="Rooms__video-wide-wrapper">
            <video autoplay muted loop preload="none">
              <source
                src="~/assets/video/section3_video1.webm"
                type="video/webm"
              />
              <source
                src="~/assets/video/section3_video1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="Rooms__virtual-space-wrapper">
            <video autoplay muted loop preload="none">
              <source
                src="~/assets/video/section3_video2.webm"
                type="video/webm"
              />
              <source
                src="~/assets/video/section3_video2.mp4"
                type="video/mp4"
              />
            </video>
            <h4 class="Rooms__virtual-space-title">
              The best<br />video<br />conferencing<br />for you.
            </h4>
            <LandingBadge
              ><LandingIconWebArrow />Your Virtual Space</LandingBadge
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
.Rooms {
  &__container {
    @include md {
      padding: 16px;
      background: $color-neutral-100;
    }
  }
  &__content-wrapper {
    padding: 16px 16px 0 16px;
    margin-top: 80px;
    width: 100%;
    background: $color-basic-white;
    @include md {
      margin-top: 0;
      padding-top: 80px;
      padding-bottom: 16px;
      border-radius: 20px;
    }
  }
  &__wrapper {
    @include md {
      max-width: 1440px;
      width: 100%;
      margin: auto;
    }
  }
  &__heading {
    @include Header-40;
    color: $color-neutral-950;
    margin-bottom: 60px;
    text-wrap: balance;
    @include md {
      @include Header-54;
      margin: auto;
      margin-bottom: 60px;
      text-align: center;
      max-width: 724px;
    }
  }
  &__title-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    position: relative;
  }
  &__coming-soon {
    position: absolute;
    right: 10px;
    top: -35px;
  }
  &__title {
    @include Header-24;
    color: $color-neutral-950;
    @include md {
      @include Header-20;
    }
    @include lg {
      @include Header-24;
    }
  }
  &__paragraph {
    @include Body-17;
    color: $color-neutral-800;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 60px;
    @include md {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 40px;
    }
    @include lg {
      column-gap: 166px;
    }
  }
  &__list-element {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__videos-wrapper {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 10px;
    margin-top: 80px;
    @include md {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 10px;
    }
  }
  &__virtual-space-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 90px 80px 16px 16px;
    background: $brand-primary-500;
    width: 100%;
    position: relative;
    border-radius: 20px;
    video {
      object-fit: cover;
      width: 165px;
      height: 140px;
      background: black;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 12px;
    }
  }
  &__virtual-space-title {
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41.6px;
    letter-spacing: -1.2px;
    text-transform: uppercase;
    color: $color-basic-white;
    margin: 0;
  }
  &__video-wide-wrapper {
    display: flex;
    flex-direction: column;
    video {
      object-fit: cover;
      border-radius: 20px;
      height: 318px;
      width: 100%;
      background: black;
    }
  }
}
</style>
