<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33317 2.9165H16.6665C17.5832 2.9165 18.3332 3.65817 18.3332 4.58317L18.3248 13.7498C18.3248 14.6665 17.5832 15.4165 16.6665 15.4165H13.3332C13.7934 15.4165 14.1665 15.7896 14.1665 16.2498C14.1665 16.7101 13.7934 17.0832 13.3332 17.0832H6.6665C6.20627 17.0832 5.83317 16.7101 5.83317 16.2498C5.83317 15.7896 6.20627 15.4165 6.6665 15.4165H3.33317C2.4165 15.4165 1.6665 14.6665 1.6665 13.7498V4.58317C1.6665 3.6665 2.4165 2.9165 3.33317 2.9165ZM6.77266 8.72765L9.46518 6.03515C9.75768 5.74265 10.2302 5.74265 10.5302 6.03515L13.2227 8.72765C13.5152 9.02015 13.5152 9.49265 13.2227 9.78515C12.9302 10.0777 12.4577 10.0777 12.1652 9.78515L10.7477 8.37515V12.2527C10.7477 12.6652 10.4102 13.0027 9.99768 13.0027C9.58518 13.0027 9.24768 12.6652 9.24768 12.2527V8.37515L7.83017 9.78515C7.53766 10.0777 7.06516 10.0777 6.77266 9.78515C6.48016 9.49265 6.48016 9.02015 6.77266 8.72765Z"
      fill="#292F33"
      fill-opacity="0.4"
    />
  </svg>
</template>
