<template>
  <section aria-label="Join" class="JoinForFree__wrapper">
    <LandingBadge icon="video-outline">Join for free at any time</LandingBadge>
    <h2 class="JoinForFree__heading">
      Embark on a journey of&nbsp;seamless collaboration!
    </h2>
    <p class="JoinForFree__paragraph">
      Discover the power of our cutting-edge video conferencing platform. Dive
      into a world where virtual meetings transcend expectations. Curious to
      witness it in action?
    </p>
    <NuxtLink to="/dashboard" class="JoinForFree__button">
      Start using for free <o-icon custom-size="mdi-18px" icon="arrow-right" />
    </NuxtLink>
  </section>
</template>
<style scoped lang="scss">
.JoinForFree {
  &__wrapper {
    margin-top: 80px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    @include md {
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      max-width: 724px;
    }
  }
  &__heading {
    @include Header-32;
    color: $color-element-dark-strong;
    margin-top: 16px;
    margin-bottom: 20px;
    @include md {
      @include Header-54;
      margin-top: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
  &__paragraph {
    @include Body-16;
    margin-bottom: 40px;
    color: $color-element-dark-medium;
    @include md {
      @include Body-17;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  &__button {
    @include button-primary;
    width: 250px;
    display: inline-flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
  }
}
</style>
