<template>
  <section id="features" aria-label="Features" class="Features__wrapper">
    <ul class="Features__list">
      <li class="Features__list-element order-1">
        <img
          src="~/assets/img/feature001.png"
          alt="KingsConference low data consumption feature"
        />
        <h3 class="Features__title">Low data consumption</h3>
        <p class="Features__paragraph">
          KingsConference prioritizes efficiency with low data consumption,
          allowing you to engage in high-quality video conferences. Our platform
          ensures that you stay connected without compromising on the quality of
          your virtual interactions.
        </p>
      </li>
      <li class="Features__list-element">
        <img
          class="Features__margin-top"
          src="~/assets/img/feature002.png"
          alt="KingsConference - easy to manage at any device"
        />
        <h3 class="Features__title">Easy to manage at any device</h3>
        <p class="Features__paragraph">
          Seamlessly handle tasks across devices with KingsConference tool,
          offering a user-friendly interface for unparalleled convenience.
        </p>
      </li>
      <li class="Features__list-element order-2">
        <img
          src="~/assets/img/feature003.png"
          alt="KingsConference meeting moderation"
        />
        <h3 class="Features__title">Full control at your fingertips</h3>
        <p class="Features__paragraph">
          From moderating participant access to managing presentation rights,
          our platform provides you with comprehensive control over every aspect
          of your virtual gatherings.
        </p>
      </li>
    </ul>
  </section>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.Features {
  &__wrapper {
    padding: 16px 16px 0 16px;
    margin-top: 80px;
    width: 100%;
    @include md {
      margin-bottom: 160px;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 80px;
    @include md {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
      max-width: 1440px;
      width: 100%;
      margin: auto;
    }
    @include lg {
      column-gap: 40px;
    }
  }
  &__list-element {
    display: flex;
    flex-direction: column;
    &.order-1 {
      order: 1;
      @include md {
        order: initial;
      }
    }
    &.order-2 {
      order: 2;
      @include md {
        order: initial;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__margin-top {
    margin-top: -4.3%;
  }
  &__title {
    @include Header-20;
    color: $color-neutral-950;
    margin-top: 24px;
    @include md {
      margin-top: 40px;
    }
  }
  &__paragraph {
    @include Body-16;
    color: $color-neutral-800;
    margin-top: 8px;
  }
}
</style>
