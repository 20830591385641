<template>
  <svg
    width="191"
    height="191"
    viewBox="0 0 191 191"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.9897 91.5296C98.1409 89.4792 100.973 86.2629 105.69 83.5469C99.3024 81.9567 94.7638 78.995 95.2195 76.5068C95.6751 74.0187 100.964 72.8483 107.504 73.6167C104.064 69.4176 102.55 65.4062 104.069 63.7891C106.003 61.7209 112.141 64.3296 117.774 69.5963C122.687 74.1974 125.591 79.3166 124.975 81.9209C124.631 84.5744 120.105 88.3356 113.887 90.9087C106.736 93.8748 100.075 94.1517 98.9897 91.5296Z"
      fill="#25272F"
    />
    <path
      d="M96.2826 26.5161C88.8941 34.8293 81.041 36.6653 81.041 36.6653C75.9619 31.1261 66.7464 31.1261 65.3393 38.9748C63.8071 47.6721 74.5638 50.0665 74.5638 50.0665C74.5638 50.0665 77.2753 61.2878 73.1164 66.8314C69.4445 71.7452 64.7273 72.5537 55.0605 73.6347C67.6577 94.9203 80.5228 93.2407 84.8067 93.2407C95.081 93.2407 97.9756 81.3181 98.7842 75.1714C96.4434 74.3539 94.058 73.6839 91.6369 73.1567L90.2521 64.848C90.2521 64.848 96.7159 67.153 102.26 63.9233C107.803 60.6936 109.188 54.6854 107.339 45.452C106.244 39.9798 106.284 33.2122 105.346 27.1638C101.688 27.7848 96.2826 26.5161 96.2826 26.5161Z"
      fill="#A15842"
    />
    <path
      d="M118.135 132.6C117.831 132.658 117.519 132.689 117.206 132.689C117.34 132.497 117.456 132.296 117.559 132.086C117.755 132.261 117.948 132.43 118.135 132.6Z"
      fill="#A15842"
    />
    <path
      d="M132.895 84.8423C132.895 84.8423 136.495 89.363 134.762 99.32"
      stroke="#30242A"
      stroke-width="3.1143"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.89 122.402C124.756 122.509 124.617 122.612 124.47 122.705C123.594 123.273 122.571 123.585 121.526 123.603C122.174 124.425 122.58 125.413 122.696 126.453C122.813 127.49 122.638 128.544 122.192 129.486C121.7 130.523 120.905 131.381 119.909 131.952C119.355 132.269 118.756 132.488 118.136 132.6C117.832 132.658 117.519 132.689 117.206 132.689C117.014 132.698 104.274 146.636 104.082 146.618L100.839 133.489C98.4804 137.558 91.3331 148.168 87.4378 153.912L38.7826 152.711L20.6597 135.937C20.6954 126.516 21.4325 117.108 22.8664 107.794C22.8664 107.794 24.7158 97.6363 27.0252 92.0927C30.7597 83.1318 37.1029 75.6315 51.0402 74.0815L55.0873 73.6348C67.6845 94.9203 80.5675 93.2407 84.8335 93.2407C95.1078 93.2407 97.9846 81.336 98.7708 75.1714C102.108 76.3239 105.994 77.9946 109.045 80.2505C113.298 83.3953 120.074 89.1399 126.444 94.6925C125.144 102.617 124.85 113.432 124.89 122.402Z"
      fill="#FF7235"
    />
    <path
      d="M116.363 124.658C116.363 124.658 115.965 117.327 115.925 107.996C115.885 99.0259 116.179 88.2112 117.479 80.2866C117.537 79.9203 117.6 79.5585 117.663 79.2056C120.258 64.9199 123.157 62.5479 126.186 62.9946C130.139 63.5574 129.219 81.1755 129.219 81.1755C129.219 81.1755 144.572 85.4326 146.533 93.2813C147.833 98.4765 166.148 161.44 121.129 161.458"
      fill="#A15842"
    />
    <path
      d="M101.366 16.3536C95.827 9.42968 80.5854 4.81074 68.5779 13.5841C56.5705 22.3574 57.4952 36.6743 57.9553 42.2179C57.9553 42.2179 45.0276 38.0591 35.7897 47.7571C33.1139 50.5311 31.1707 53.9217 30.121 57.6249C29.0757 61.3325 28.9596 65.2412 29.786 69.0025C29.786 69.0025 12.7754 68.5245 9.46528 84.2441C7.58465 93.1782 11.0958 99.1239 14.991 102.805C17.2737 104.949 19.945 106.642 22.8575 107.794C22.8575 107.794 24.7069 97.6364 27.0164 92.0927C30.7508 83.1318 37.0941 75.6316 51.0313 74.0815L55.0785 73.6348C64.7452 72.5538 69.4624 71.7318 73.1344 66.8315C77.2887 61.2878 74.5638 50.0666 74.5638 50.0666C74.5638 50.0666 63.7937 47.6722 65.3259 38.9838C66.7152 31.1352 75.9486 31.1352 81.0277 36.6743C81.0277 36.6743 88.8808 34.8294 96.2693 26.5162C96.2693 26.5162 101.674 27.7849 105.36 27.1639C104.73 23.0006 103.622 19.1813 101.366 16.3536Z"
      fill="#5459D0"
    />
    <path
      d="M28.9639 69.3774C29.4552 71.6914 30.3353 73.907 31.5726 75.9217"
      stroke="black"
      stroke-width="2.08062"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M102.787 109.032C105.614 114.124 105.579 120.235 105.212 123.858"
      stroke="#A15842"
      stroke-width="2.08062"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M147.493 99.4548C146.184 98.8517 145.403 97.3329 145.626 94.4293C145.626 94.4293 142.919 97.8243 140.038 96.3144C137.156 94.8046 138.309 91.5525 138.309 91.5525C138.309 91.5525 135.709 93.2857 133.547 91.1058C131.586 89.1448 133.1 85.9106 133.1 85.9106C133.1 85.9106 130.223 87.0855 129.205 85.044C127.923 82.4889 129.482 79.8131 131.17 77.7627"
      stroke="#30242A"
      stroke-width="4.16123"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M90.0152 65.7013C88.0809 64.9419 86.1914 64.0663 84.3599 63.0791"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M93.4776 78.5215L92.7227 73.7373"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.022 42.3921L57.9824 50.3792"
      stroke="black"
      stroke-width="2.08062"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M90.1899 52.5857C91.2665 52.1346 94.4962 52.5098 95.5191 55.5697"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M76.811 42.8032L72.4824 40.6411"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.223 43.9106C100.719 45.0319 101.197 46.2693 101.094 46.7428C100.942 47.319 100.598 47.8238 100.12 48.1812"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M74.4392 48.0341L68.5292 53.609C68.2076 53.9128 67.9709 54.288 67.8413 54.7079C67.7073 55.1278 67.685 55.5745 67.7743 56.0033C67.8637 56.4322 68.0647 56.8342 68.3506 57.1648C68.6364 57.4953 69.0072 57.75 69.4227 57.8974L76.4091 60.3766C76.8246 60.524 77.2713 60.5597 77.7046 60.4838C78.1379 60.4079 78.5444 60.2247 78.8884 59.9478C79.2323 59.6708 79.4959 59.309 79.6567 58.9025C79.8175 58.4915 79.8756 58.0493 79.8175 57.6115L78.7409 49.5708C78.6784 49.1017 78.4863 48.664 78.1915 48.2977C77.8967 47.9314 77.5036 47.6544 77.0569 47.4936C76.6146 47.3328 76.1322 47.3015 75.6721 47.3953C75.212 47.4891 74.7831 47.708 74.4392 48.0296V48.0341Z"
      fill="#F4E6D8"
    />
    <path
      d="M170.271 175.926H95.2954L112.337 122.084C112.842 120.498 113.838 119.114 115.178 118.135C116.523 117.153 118.14 116.625 119.806 116.625H183.163C184.396 116.625 185.606 116.916 186.705 117.47C187.804 118.028 188.755 118.832 189.483 119.828C190.212 120.82 190.694 121.973 190.895 123.188C191.096 124.403 191.007 125.649 190.636 126.819L176.542 171.343C176.118 172.674 175.283 173.835 174.157 174.657C173.031 175.479 171.669 175.926 170.275 175.926H170.271Z"
      fill="white"
    />
    <path
      d="M51.7821 169.864C51.5632 170.561 51.5051 171.303 51.6257 172.022C51.7463 172.746 52.0322 173.429 52.4611 174.019C52.8944 174.608 53.4572 175.091 54.1094 175.421C54.7616 175.752 55.4853 175.926 56.2134 175.926H170.267C171.66 175.926 173.018 175.475 174.144 174.653C175.27 173.827 176.101 172.665 176.52 171.334L176.967 169.864H51.7821Z"
      fill="white"
    />
    <path
      d="M155.708 89.8814C155.605 90.5514 155.462 91.2081 155.279 91.8424C154.725 93.8213 153.827 95.5903 152.84 96.9572C151.589 98.6815 150.191 99.7536 149.132 99.7536C146.899 99.7536 145.322 98.3822 145.626 94.3931C145.626 94.3931 142.919 97.7881 140.037 96.2782C137.156 94.7684 138.309 91.5163 138.309 91.5163C138.309 91.5163 135.709 93.2495 133.547 91.0696C131.586 89.1086 133.1 85.8744 133.1 85.8744C133.1 85.8744 130.223 87.0493 129.205 85.0078C127.038 80.6792 132.997 76.0246 133.967 74.6219L131.398 72.5224L132.68 69.9047C132.68 69.9047 135.709 68.1715 138.309 69.0381C142.329 70.3782 141.739 74.1216 141.739 74.1216C141.739 74.1216 147.377 72.0891 148.471 78.0481C148.471 78.0481 153.555 79.1872 152.411 84.2707C152.411 84.2707 156.525 84.4896 155.708 89.8814Z"
      fill="#A15842"
    />
    <path
      d="M131.909 86.5471C134.8 83.9719 139.721 79.1124 142.056 74.0127"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.281 92.5159C141.76 88.5513 145.414 83.6766 148.025 78.1909"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M145.041 96.097C146.286 94.5721 150.641 89.0934 152.8 84.7563"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.82223 127.11C9.79605 127.486 10.8547 127.584 11.8777 127.392C12.9007 127.2 13.8566 126.731 14.6294 126.029C14.7232 127.075 15.1029 128.071 15.7328 128.906C16.3626 129.746 17.2114 130.389 18.1852 130.769C19.159 131.149 20.2222 131.247 21.2541 131.059C22.2815 130.867 23.2375 130.389 24.0103 129.683C24.1041 130.724 24.4882 131.72 25.1181 132.556C25.7479 133.391 26.5967 134.034 27.5705 134.414C28.5443 134.794 29.6075 134.892 30.6349 134.7C31.6623 134.508 32.6183 134.034 33.3911 133.329C33.4626 134.15 33.7172 134.946 34.1282 135.66C34.5436 136.375 35.1109 136.987 35.7899 137.456C36.4689 137.925 37.2417 138.238 38.0592 138.376C38.8722 138.51 39.7075 138.466 40.5027 138.242L47.2569 120.102C49.8567 113.137 51.0896 105.74 50.8886 98.3067L50.2051 74.1846C50.2051 74.1846 34.6195 75.48 25.5291 86.737C20.8654 92.5129 11.7124 107.772 5.84717 124.363C6.46362 125.614 7.52232 126.592 8.82223 127.106V127.11Z"
      fill="#FF7235"
    />
    <path
      d="M36.9603 138.068C35.982 137.693 35.1288 137.049 34.4989 136.21C33.8691 135.374 33.4849 134.374 33.3866 133.333C32.6138 134.039 31.6579 134.512 30.6305 134.704C29.603 134.896 28.5399 134.798 27.5661 134.418C26.5922 134.039 25.7435 133.395 25.1136 132.56C24.4838 131.725 24.0996 130.729 24.0058 129.688C23.233 130.394 22.2771 130.872 21.2496 131.064C20.2222 131.256 19.159 131.157 18.1808 130.778C17.2069 130.398 16.3537 129.755 15.7283 128.915C15.0985 128.075 14.7188 127.079 14.625 126.038C13.9817 126.623 13.2134 127.052 12.3736 127.289C11.5382 127.526 10.6582 127.566 9.80054 127.405C8.94733 127.244 8.13879 126.887 7.4464 126.364C6.754 125.842 6.19115 125.158 5.80252 124.381C0.718997 138.76 -1.8987 154.145 3.024 165.219C13.6199 189.068 45.4209 180.675 52.9256 178.468C60.4303 176.261 75.0108 168.752 84.7266 170.079C94.4425 171.406 105.481 176.261 107.25 170.079C107.25 170.079 113.195 169.927 111.324 164.272C111.324 164.272 114.312 163.901 114.754 161.694C115.648 157.276 105.038 153.305 105.038 153.305C105.038 153.305 113.432 154.645 115.196 152.858C117.43 150.625 115.259 147.02 101.55 143.696C86.4911 140.051 66.6171 142.258 37.0273 147.556L40.4893 138.251C39.3234 138.577 38.0815 138.519 36.9558 138.077L36.9603 138.068Z"
      fill="#A15842"
    />
    <path
      d="M47.3729 117.698L37.4873 146.555"
      stroke="#A15842"
      stroke-width="2.08062"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M107.709 153.613C102.912 151.791 94.9244 148.994 88.7241 147.985"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M111.453 164.03C105.289 161.256 93.5135 156.222 87.1792 155.275"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.1032 146.618C33.7797 147.154 28.5622 148.043 23.3491 149.084"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.071 170.677C102.448 168.323 92.7049 163.642 85.8569 162.337"
      stroke="#30242A"
      stroke-width="2.08062"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M104.109 43.9111C104.824 43.9111 105.4 43.3348 105.4 42.6201C105.4 41.9054 104.824 41.3291 104.109 41.3291C103.395 41.3291 102.818 41.9054 102.818 42.6201C102.818 43.3348 103.395 43.9111 104.109 43.9111Z"
      fill="#30242A"
    />
    <path
      d="M94.1474 42.2001C94.8622 42.2001 95.4384 41.6239 95.4384 40.9091C95.4384 40.1944 94.8622 39.6182 94.1474 39.6182C93.4327 39.6182 92.8564 40.1944 92.8564 40.9091C92.8564 41.6239 93.4327 42.2001 94.1474 42.2001Z"
      fill="#30242A"
    />
    <path
      d="M133.851 63.8834C133.851 67.0505 132.957 70.0122 131.403 72.5227C130.424 74.104 129.187 75.5067 127.749 76.6726C124.921 78.9642 121.321 80.3356 117.398 80.3356C108.312 80.3356 100.946 72.9694 100.946 63.8834C100.946 54.7973 108.312 47.4312 117.398 47.4312C126.485 47.4312 133.851 54.7973 133.851 63.8834Z"
      fill="#71475C"
    />
    <path
      d="M133.967 74.622L130.308 78.8657L127.749 76.6724C129.187 75.5065 130.424 74.1038 131.403 72.5225L133.967 74.622Z"
      fill="#71475C"
    />
    <path
      d="M105.976 59.0185C108.661 52.7154 115.955 49.7806 122.258 52.4653C126.265 54.1717 128.91 57.7364 129.611 61.7211C130.027 64.0171 129.794 66.4472 128.816 68.7477C126.127 75.0552 118.837 77.9901 112.529 75.3054C112.319 75.2161 112.114 75.1223 111.913 75.0195C106.034 72.1383 103.376 65.116 105.976 59.0185Z"
      fill="#25272F"
    />
    <path
      d="M112.717 67.9882C115.402 61.6852 122.696 58.7503 129.004 61.435C129.214 61.5244 129.419 61.6182 129.616 61.7209C130.031 64.017 129.799 66.4471 128.821 68.7476C126.131 75.0551 118.841 77.99 112.534 75.3053C112.324 75.2159 112.118 75.1221 111.917 75.0194C111.502 72.7233 111.734 70.2932 112.717 67.9882Z"
      fill="#A15842"
    />
  </svg>
</template>
