<template>
  <section id="about" aria-label="About" class="About__container">
    <div class="About__content-wrapper">
      <div class="About__wrapper">
        <LandingBadge class="About__badge" icon="creation"
          >Uniting a thousand voices</LandingBadge
        >
        <h2 class="About__heading">
          Host Events with Up&nbsp;to&nbsp;1,000 Participants!
        </h2>
        <p class="About__paragraph">
          KingsConference seamlessly brings people together, fostering effective
          communication and collaboration across boardrooms, classrooms,
          operating rooms, and every virtual space in between.
        </p>
        <NuxtLink class="About__button" to="/dashboard" :external="true">
          Start exploring now
          <o-icon custom-size="mdi-18px" icon="arrow-right" />
        </NuxtLink>
      </div>
      <div class="About__illustration-wrapper">
        <LandingIllustrationAbout />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.About {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__content-wrapper {
    @include md {
      max-width: 1440px;
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__wrapper {
    padding: 16px 16px 0 16px;
    margin-top: 80px;
    @include md {
      max-width: 550px;
    }
  }
  &__badge {
    margin-bottom: 16px;
  }
  &__heading {
    @include Header-40;
    color: $color-neutral-950;
    margin-bottom: 20px;
    text-wrap: balance;
    @include md {
      @include Header-54;
      margin-bottom: 20px;
    }
  }
  &__paragraph {
    @include Body-16;
    color: $color-neutral-800;
    margin-bottom: 40px;
  }
  &__button {
    @include button-primary;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    width: unset;
    width: 250px;
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
  }
  &__illustration-wrapper {
    margin-top: 80px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    @include md {
      margin: 0;
    }
  }
}
</style>
