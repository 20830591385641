<template>
  <div class="Accordion__wrapper">
    <h3 class="Accordion__trigger-wrapper">
      <button class="Accordion__trigger" @click="handleClick">
        <div class="Accordion__icon-container">
          <span class="icon-1"></span>
          <span class="icon-2" :class="{ active: active }"></span>
        </div>
        <slot name="title" />
      </button>
    </h3>
    <div class="Accordion__content" :class="{ active: active }">
      <div>
        <p class="Accordion__content-wrapper">
          <slot name="content" />
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
type Props = {
  id: string;
  active: boolean;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "toggle", id: string): void;
}>();

const handleClick = () => {
  emit("toggle", props.id);
};
</script>
<style scoped lang="scss">
.Accordion {
  &__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid $color-neutral-800;
  }
  &__trigger-wrapper {
    margin: 0;
  }
  &__trigger {
    @include Label-16;
    border: none;
    background: none;
    color: $color-element-light-strong;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 16px;
    text-align: left;
    padding: 0;
    cursor: pointer;
  }
  &__icon-container {
    position: relative;
    width: 16px;
    height: 16px;
    min-width: 16px;
    .icon-1 {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background: $brand-primary-500;
      border-radius: 999px;
    }
    .icon-2 {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background: $brand-primary-500;
      transition: transform 0.4s ease-in;
      border-radius: 999px;
      &.active {
        transform: rotate(-90deg);
      }
    }
  }
  &__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    & > div {
      overflow: hidden;
    }
    &.active {
      grid-template-rows: 1fr;
    }
  }
  &__content-wrapper {
    @include Body-16;
    padding-left: 16px;
    margin: 24px 0 12px 32px;
    color: $color-element-light-medium;
    border-left: 1px solid $color-neutral-800;
  }
}
</style>
