<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="26" fill="#16B07C" />
    <path
      d="M31.8333 24.25V20.1667C31.8333 19.525 31.3083 19 30.6667 19H16.6667C16.025 19 15.5 19.525 15.5 20.1667V31.8333C15.5 32.475 16.025 33 16.6667 33H30.6667C31.3083 33 31.8333 32.475 31.8333 31.8333V27.75L34.505 30.4217C35.24 31.1567 36.5 30.6317 36.5 29.5933V22.395C36.5 21.3567 35.24 20.8317 34.505 21.5667L31.8333 24.25Z"
      fill="white"
    />
  </svg>
</template>
