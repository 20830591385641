<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.08626 17.7475H16.9113C17.3696 17.7475 17.7446 17.3725 17.7446 16.9141V3.08912C17.7446 2.34745 16.8446 1.97245 16.3196 2.49745L2.50292 16.3225C1.97792 16.8475 2.34459 17.7475 3.08626 17.7475ZM16.0779 16.0808H13.5779V7.60578L16.0779 5.10578V16.0808Z"
      fill="#292F33"
      fill-opacity="0.4"
    />
  </svg>
</template>
