<template>
  <section id="home" aria-label="Home" class="Hero__container">
    <div class="Hero__wrapper">
      <ClientOnly>
        <img
          :style="layer0"
          class="Hero__avatar l1"
          src="~/assets/img/hero/avatar1L.png"
          alt=""
        />
        <img
          :style="layer1"
          class="Hero__avatar l2"
          src="~/assets/img/hero/avatar2L.png"
          alt=""
        />
        <img
          :style="layer2"
          class="Hero__avatar l3"
          src="~/assets/img/hero/avatar3L.png"
          alt=""
        />
        <img
          :style="layer3"
          class="Hero__avatar l4"
          src="~/assets/img/hero/avatar4L.png"
          alt=""
        />
        <img
          :style="layer4"
          class="Hero__avatar r1"
          src="~/assets/img/hero/avatar1R.png"
          alt=""
        />
        <img
          :style="layer5"
          class="Hero__avatar r2"
          src="~/assets/img/hero/avatar2R.png"
          alt=""
        />
        <img
          :style="layer6"
          class="Hero__avatar r3"
          src="~/assets/img/hero/avatar3R.png"
          alt=""
        />
        <img
          :style="layer7"
          class="Hero__avatar r4"
          src="~/assets/img/hero/avatar4R.png"
          alt=""
        />
      </ClientOnly>
      <LandingBadge class="Hero__badge" icon="video-outline"
        >Join for free at any time</LandingBadge
      >
      <h1 class="Hero__heading">
        New is coming! Better way of&nbsp;conferencing
      </h1>
      <p class="Hero__paragraph">
        Connect, collaborate, and get more done together with KingsConference's
        reliable audio & video meeting solution.
      </p>
      <NuxtLink class="Hero__button" to="/dashboard" :external="true"
        ><IconKingschat /> Login with KingsChat</NuxtLink
      >
      <div class="Hero__video-wrapper">
        <video autoplay muted loop preload="none">
          <source src="~/assets/video/hero_animation.webm" type="video/webm" />
          <source src="~/assets/video/hero_animation.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useParallax } from "@vueuse/core";
const { tilt, roll } = useParallax(process.client ? document.body : null);
const layer0 = computed(() => ({
  transform: `translateX(${tilt.value * 10}px) translateY(${
    roll.value * 10
  }px)`,
}));
const layer1 = computed(() => ({
  transform: `translateX(${tilt.value * 10}px) translateY(${
    roll.value * 10
  }px)`,
}));
const layer2 = computed(() => ({
  transform: `translateX(${tilt.value * 10}px) translateY(${
    roll.value * -10
  }px)`,
}));
const layer3 = computed(() => ({
  transform: `translateX(${tilt.value * 7}px) translateY(${roll.value * 6}px)`,
}));
const layer4 = computed(() => ({
  transform: `translateX(${tilt.value * 9}px) translateY(${roll.value * 5}px)`,
}));
const layer5 = computed(() => ({
  transform: `translateX(${tilt.value * -8}px) translateY(${
    roll.value * -9
  }px)`,
}));
const layer6 = computed(() => ({
  transform: `translateX(${tilt.value * 2}px) translateY(${roll.value * 4}px)`,
}));
const layer7 = computed(() => ({
  transform: `translateX(${tilt.value * 4}px) translateY(${roll.value * -5}px)`,
}));
</script>
<style scoped lang="scss">
.Hero {
  &__container {
    margin-top: 80px;
  }
  &__avatar {
    display: none;
    @include custom-min-width(992px) {
      display: initial;
      position: absolute;
      object-fit: contain;
    }
    &.l1 {
      width: 70px;
      height: 70px;
      top: -50px;
      left: 60px;
      @include lg {
        top: 0;
        left: 170px;
      }
    }
    &.l2 {
      width: 42px;
      height: 42px;
      top: 110px;
      left: 57px;
      @include lg {
      }
    }
    &.l3 {
      width: 82px;
      height: 82px;
      top: 250px;
      left: 140px;
      @include lg {
        left: 240px;
      }
    }
    &.l4 {
      width: 56px;
      height: 56px;
      top: 340px;
      left: 35px;
      @include lg {
        top: 360px;
        left: 75px;
      }
    }
    &.r1 {
      width: 70px;
      height: 70px;
      top: -60px;
      right: 80px;
      @include lg {
        width: 96px;
        height: 96px;
        top: -40px;
        right: 164px;
      }
    }
    &.r2 {
      width: 42px;
      height: 42px;
      top: 140px;
      right: 200px;
      @include lg {
        right: 78px;
      }
    }
    &.r3 {
      width: 42px;
      height: 42px;
      top: 280px;
      right: 320px;
      @include lg {
      }
    }
    &.r4 {
      width: 58px;
      height: 58px;
      top: 250px;
      right: 50px;
      @include lg {
        top: 350px;
        right: 150px;
      }
    }
  }
  &__wrapper {
    padding: 16px 16px 0 16px;
    @include md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1440px;
      margin: auto;
      position: relative;
    }
  }
  &__badge {
    margin-bottom: 16px;
    @include md {
      display: none;
    }
  }
  &__heading {
    @include Header-40;
    color: $color-neutral-950;
    margin-bottom: 20px;
    @include md {
      @include Header-54;
      margin-bottom: 20px;
      max-width: 850px;
      text-align: center;
    }
    @include lg {
      @include Header-70;
    }
  }
  &__paragraph {
    @include Body-16;
    color: $color-neutral-800;
    margin-bottom: 40px;
    @include md {
      @include Body-17;
      max-width: 460px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  &__button {
    @include button-primary;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    width: 250px;
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
      svg {
        display: none;
      }
    }
  }
  &__video-wrapper {
    margin-top: 100px;
    overflow: hidden;
    video {
      border-radius: 24px;
      margin-top: 1px;
      width: 100%;
      object-fit: cover;
      transform: translateY(6px);
    }
    @include md {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
    }
  }
}
</style>
