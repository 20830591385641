<template>
  <section aria-label="Advantages" class="Advantages__wrapper">
    <div class="Advantages__row">
      <LandingAdvantage
        v-for="advantage in advantages1"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
      <LandingAdvantage
        v-for="advantage in advantages1"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
    </div>
    <div class="Advantages__row">
      <LandingAdvantage
        v-for="advantage in advantages2"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
      <LandingAdvantage
        v-for="advantage in advantages2"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
    </div>
    <div class="Advantages__row">
      <LandingAdvantage
        v-for="advantage in advantages3"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
      <LandingAdvantage
        v-for="advantage in advantages3"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
    </div>
    <div class="Advantages__row">
      <LandingAdvantage
        v-for="advantage in advantages4"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
      <LandingAdvantage
        v-for="advantage in advantages4"
        :key="advantage.icon"
        :icon="advantage.icon"
        >{{ advantage.text }}</LandingAdvantage
      >
    </div>
  </section>
</template>
<script setup lang="ts">
import { AdvantageIcon } from "../Advantage/types";
type Advantage = {
  icon: AdvantageIcon;
  text: string;
};
const advantages: Advantage[] = [
  {
    icon: "audio",
    text: "Audio rooms",
  },
  {
    icon: "video",
    text: "Video rooms",
  },
  {
    icon: "streamed",
    text: "Streamed rooms",
  },
  { icon: "screen-sharing", text: "Easy screen sharing" },
  {
    icon: "invite",
    text: "Easy invite",
  },
  {
    icon: "quality",
    text: "High quality video & sound",
  },
  {
    icon: "seamless",
    text: "Seamless audio conferencing",
  },
  {
    icon: "control",
    text: "Total meeting control",
  },
  {
    icon: "capacity",
    text: "Unmatched capacity",
  },
  {
    icon: "privacy",
    text: "Room privacy control",
  },
  {
    icon: "data-consumption",
    text: "Low data consumption",
  },
  {
    icon: "reactions",
    text: "Reactions",
  },
  {
    icon: "chat",
    text: "Live chat",
  },
];

const advantages1 = [
  advantages[0],
  advantages[3],
  advantages[7],
  advantages[9],
  advantages[11],
  advantages[4],
  advantages[2],
  advantages[6],
  advantages[1],
  advantages[10],
  advantages[8],
  advantages[5],
  advantages[12],
];
const advantages2 = [
  advantages[5],
  advantages[10],
  advantages[2],
  advantages[12],
  advantages[11],
  advantages[6],
  advantages[8],
  advantages[1],
  advantages[0],
  advantages[4],
  advantages[3],
  advantages[7],
  advantages[9],
];
const advantages3 = [
  advantages[8],
  advantages[1],
  advantages[9],
  advantages[7],
  advantages[10],
  advantages[11],
  advantages[2],
  advantages[4],
  advantages[5],
  advantages[0],
  advantages[3],
  advantages[12],
  advantages[6],
];
const advantages4 = [
  advantages[2],
  advantages[4],
  advantages[7],
  advantages[3],
  advantages[8],
  advantages[6],
  advantages[12],
  advantages[1],
  advantages[9],
  advantages[11],
  advantages[0],
  advantages[10],
  advantages[5],
];
</script>
<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5355px);
  }
}
.Advantages {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-top: 80px;
    @include md {
      margin-bottom: 80px;
    }
  }
  &__row {
    animation: scroll 50s linear infinite;
    display: flex;
    column-gap: 16px;
    width: calc(5355px * 2);
    &:nth-child(odd) {
      animation: scroll 50s linear infinite reverse;
    }
  }
}
</style>
