<template>
  <div class="About__desktop">
    <img loading="lazy" src="~/assets/img/about-desktop.png" alt="" />
  </div>
</template>
<style scoped lang="scss">
.About {
  &__desktop {
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
