<template>
  <section id="faq" aria-label="FAQ" class="Faq__wrapper">
    <div class="Faq__content-wrapper">
      <div class="Faq__header-wrapper">
        <h2 class="Faq__heading">Questions?<br />Answers.</h2>
        <p class="Faq__description">
          <span>Still not sure?</span> Contact us to see if we'd be a good fit.
        </p>
        <button class="Faq__button" @click="handleOpenSupport">
          Contact support <o-icon custom-size="mdi-18px" icon="arrow-right" />
        </button>
      </div>
      <div class="Faq__questions-wrapper">
        <LandingAccordion
          v-for="question in questions"
          :id="question.id"
          :key="question.id"
          :active="accordionOpenId === question.id"
          @toggle="handleAccordionToggle"
        >
          <template #title>{{ question.title }}</template>
          <template #content>{{ question.content }}</template>
        </LandingAccordion>
        <LandingAccordion
          :id="'question-5'"
          :active="accordionOpenId === 'question-5'"
          @toggle="handleAccordionToggle"
        >
          <template #title
            >What support options are available for event hosts and participants
            in KingsConference?</template
          >
          <template #content
            >KingsConference provides comprehensive technical support for event
            hosts and participants alike. Our dedicated support team is
            available via email:
            <a class="Faq__link" href="mailto:support@joinkingschat.com"
              >support@joinkingschat.com</a
            >
            to address any questions or concerns.</template
          >
        </LandingAccordion>
      </div>
      <div class="Faq__illustration-wrapper">
        <LandingIllustrationFaq />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useSupportStore } from "~/store/support";
const accordionOpenId = ref("");

const supportStore = useSupportStore();

const handleOpenSupport = () => {
  supportStore.openSupport();
};

const handleAccordionToggle = (id: string) => {
  if (accordionOpenId.value === id) {
    accordionOpenId.value = "";
    return;
  }
  accordionOpenId.value = id;
};

const questions = [
  {
    id: "question-1",
    title: "How many participants can join an event hosted by KingsConference?",
    content: `KingsConference allows events with up to 1,000 participants, providing a seamless experience for large gatherings such as boardrooms, classrooms, operating rooms, and various virtual spaces.`,
  },
  {
    id: "question-2",
    title: "Is KingsConference platform secure for hosting large events?",
    content: `Yes, security is a top priority at KingsConference. We employ robust security measures, including encryption in transit, to ensure that all interactions, data, and communications within our platform are fully protected.`,
  },
  {
    id: "question-3",
    title:
      "Can participants join events without downloading any software for KingsConference?",
    content: `Yes, participants can easily join events hosted on KingsConference without downloading any additional software. They can simply access the event link provided and join directly from their web browser, ensuring a hassle-free experience.`,
  },
  {
    id: "question-4",
    title:
      "What collaboration features are available during KingsConference events?",
    content: `KingsConference offers a range of collaboration tools to enhance the event experience. Participants can share screens, engage in real-time discussions, utilize interactive features, and collaborate on documents, fostering effective communication and collaboration.`,
  },
];
</script>
<style scoped lang="scss">
.Faq {
  &__wrapper {
    margin-top: 16px;
    border-radius: 20px;
    background: $color-neutral-950;
  }
  &__content-wrapper {
    display: flex;
    padding: 80px 16px;
    flex-direction: column;
    row-gap: 60px;
    @include md {
      max-width: 1440px;
      width: 100%;
      margin: auto;
    }
    @include custom-min-width(1200px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 80px;
      grid-row-gap: 0px;
      padding: 110px 40px 80px 40px;
    }
  }
  &__header-wrapper {
    @include custom-min-width(1200px) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  &__heading {
    @include Header-40;
    color: $color-element-light-strong;
    margin-bottom: 32px;
    @include md {
      @include Header-54;
      margin-bottom: 8px;
    }
  }
  &__description {
    @include Header-20;
    color: $color-element-light-basic;
    margin-bottom: 24px;
    span {
      color: $color-element-light-strong;
    }
  }
  &__button {
    @include button-primary;
    color: $color-neutral-950;
    background: $color-element-light-strong;
    width: initial;
    display: inline-flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
  }
  &__questions-wrapper {
    @include custom-min-width(1200px) {
      max-height: 700px;
      height: 700px;
      margin-left: auto;
      max-width: 470px;
      overflow-y: auto;
      grid-area: 1 / 2 / 3 / 3;
      padding-right: 24px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: $color-neutral-100;
        border-radius: 999px;
      }
      &::-webkit-scrollbar-thumb {
        background: $brand-primary-500;
        border-radius: 999px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $brand-primary-500;
        cursor: pointer;
      }
    }
  }
  &__illustration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include custom-min-width(1200px) {
      justify-content: center;
      align-items: flex-start;
      grid-area: 2 / 1 / 3 / 2;
    }
  }
  &__link {
    color: $brand-primary-500;
  }
}
</style>
