<template>
  <div>
    <LandingHero />
    <LandingSectionAbout />
    <LandingSectionFeatures />
    <LandingSectionRooms />
    <LandingSectionPlans />
    <LandingSectionFaq />
    <LandingSectionJoinForFree />
    <LandingSectionAdvantages />
  </div>
</template>
<script setup lang="ts">
definePageMeta({ layout: "landing" });
useSeoMeta({
  title: "KingsConference - take your online meetings to the new heights",
  ogTitle: "KingsConference - take your online meetings to the new heights",
  description:
    "KingsConference seamlessly brings people together, fostering effective communication and collaboration across boardrooms, classrooms, operating rooms, and every virtual space in between.",
  ogDescription:
    "KingsConference seamlessly brings people together, fostering effective communication and collaboration across boardrooms, classrooms, operating rooms, and every virtual space in between.",
  ogImage:
    "https://assets.kingsconference.app/og-images/link_preview_landing_page.png",
});
</script>
